<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="red--text">
            What the h*ck is going on here?
          </v-card-title>
          <br />
          <v-card-subtitle>
            <b>How it works</b>
          </v-card-subtitle>
          <v-card-text>
            <p>
              This is a dating service that works just like the real world. We
              apply for the things we want—jobs, housing, you name it.
            </p>
            <p>
              At Plan-It Dating, we believe in informed consent: you should be
              able to know ahead of time what you’ll be doing, how you’re both
              getting there, etc. No more surprise activities and no more
              awkward uncertainty when the check comes. You get all the
              information ahead of time, and you get to choose.
            </p>
            <p>
              There are two roles here: the <b>planner</b> and the
              <b>applicant</b>.
            </p>
            <p>
              The <b>planner</b> will plan out every detail for an awesome date
              (day, time, activity, and even who pays!). It’s then posted on our
              Instagram (
              <v-btn
                large
                href="https://www.instagram.com/planitdating/"
                target="_blank"
                icon
                color="#F56040"
                ><v-icon>mdi-instagram </v-icon></v-btn
              ><a href="https://www.instagram.com/planitdating/" target="_blank"
                >@planitdating</a
              >). To plan a date, click
              <a href="https://forms.gle/MVnehjjXLbJswBEA8" target="_blank"
                >here</a
              >!
            </p>
            <p>
              The <b>applicant</b> can look through all of the dates right here
              on this site, and then apply to their favorite ones! Each
              <b>applicant</b> can say why they’re the best choice, and what
              they’d contribute to the date. To apply for a date, click
              <a target="_blank" href="https://forms.gle/FGy884CMospGmRC68"
                >here</a
              >!
            </p>
            <p>
              If multiple <b>applicants</b> apply to a single date, the planner
              has to choose their favorite.
            </p>
            <p>
              Finally, we put the <b>planner</b> and the selected
              <b>applicant</b> in contact with each other… The rest is up to
              you!
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer> </v-spacer>
            <v-btn to="/" color="#0083b0"> return home </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <about />
</template>

<script>
  import about from '../components/About'

  export default {
    name: 'About',

    components: {
      about,
    },
  }
</script>
